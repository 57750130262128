/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Indic+Siyaq+Numbers&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Padauk:wght@400;700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap') */
@import url('https://fonts.googleapis.com/css2?family=Padauk:wght@400;700&family=Raleway:ital,wght@0,100..900;1,100..900&family=Tangerine:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  display: flex;
  justify-content: center;
}

* {
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Noto Sans Indic Siyaq Numbers', sans-serif;
  /* font-family: 'Raleway', sans-serif; */
  /* font-family: 'Sansita', sans-serif !important; */
}

body {
  word-spacing: 0.2em;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 1em;
  font-family: 'Sansita', sans-serif !important;
}
h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 1em;
}
h3 {
  font-size: 1.2em;
  /* font-weight: bold; */
  margin-bottom: 1em;
}
h4 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid #00000012;
  border-bottom: 1px solid rgb(255 255 255 / 23%);
  margin-top: 1em;
  margin-bottom: 1em;
}

.font-sansita {
  font-family: 'Sansita', sans-serif !important;
  font-weight: 400;
  font-style: normal;
}
